import axios from "axios";
import { TokenService } from "./token.service";
import { navigate } from "@reach/router";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../constants/app-constants";
export const URL_USER_AUTHENTICATE = "api/token/pair";
export const URL_REFRESH_TOKEN = "api/token/refresh";

const instance = axios.create({
  baseURL: `${process.env.API_URL}`,
  timeout: 1000 * 10, // Wait for 10 seconds
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();

    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      //   config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    // res.status= err?.response?.status;
    return res;
  },
  async (err) => {
    //  START HANDLE timeExceedError
    const resMessage =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    
    if (resMessage === ERROR_MESSAGES.timeExceedErrorToCompaire) {
      // toast.error(ERROR_MESSAGES.timeExceedError);
      let res = {
        status: "408",
        data: ERROR_MESSAGES.timeExceedError,
      };
      return res;
    }
    //  END HANDLE timeExceedError

    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (
        err?.response?.status === 401 &&
        err.response.config.url === URL_USER_AUTHENTICATE
      ) {
        let res = {
          status: err?.response?.status,
          data: err?.response?.data,
        };
        return res;
      }
      if (
        err?.response?.status === 401 &&
        !originalConfig._retry &&
        err.response.config.url !== URL_REFRESH_TOKEN
      ) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post(URL_REFRESH_TOKEN, {
            refresh: TokenService.getLocalRefreshToken(),
          });

          if (rs.status === 403) {
            toast.error(ERROR_MESSAGES.sessionExpired);
            TokenService.removeUser();
            navigate("/app/login");
            let res = {
              status: 403,
              message: ERROR_MESSAGES.sessionExpire,
            };
            return res;
          } else {
            const { access } = rs.data;
            TokenService.updateLocalAccessToken(access);
            instance.defaults.headers.common["Authorization"] =
              "Bearer " + TokenService.getLocalAccessToken();
            return instance(originalConfig);
          }
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (err?.response?.status === 500 && !originalConfig._retry) {
        toast.error(ERROR_MESSAGES.responce500);
        let res = {
          status: err?.response?.status,
          message: ERROR_MESSAGES.responce500,
          data: err?.response?.data,
        };
        return res;
      } else if (err?.response?.status === 403 && !originalConfig._retry) {
        toast.error(ERROR_MESSAGES.sessionExpire);
        TokenService.removeUser();
        navigate("/app/login");
        return Promise.reject(err.response);
      } else if (
        err?.response?.status === 401 &&
        err.response.config.url === URL_REFRESH_TOKEN
      ) {
        // toast.error(ERROR_MESSAGES.sessionExpire);
        TokenService.removeUser();
        navigate("/app/login");
        let res = {
          status: 403,
          message: ERROR_MESSAGES.sessionExpire,
        };
        return res;
      } else if (err?.response?.status === 404 && !originalConfig._retry) {
        toast.error(
          err?.response?.data?.error
            ? err?.response?.data?.error
            : ERROR_MESSAGES.serverUnreachable
        );
        let res = {
          status: err?.response?.status,
          // "message": err?.data?.error ? err?.data?.error : "Can’t reach to server at the moment! Please try after sometime…",
          data: err?.response?.data,
        };
        return res;
      } else {
        let res = {
          status: err?.response?.status,
          data: err?.response?.data,
        };
        return res;
      }
      // else if (err?.response?.status === 422 && !originalConfig._retry) {
      //   toast.error("Invalid Parameter");
      //   let res = {
      //     "status" : err?.response?.status,
      //     "message" : "Invalid Parameter"
      //   }
      //   return (res);
      // }
    }

    return Promise.reject(err);
  }
);

export default instance;
