import jwt_decode from "jwt-decode";
import { ADMIN_ROLE_ACCESS, PRODUCT_ROLE_ACCESS } from "../constants/Roles";

export const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  getAccess,
  setUser,
  removeUser,
  updateLocalUser,
  getUserId,
};

function getLocalRefreshToken() {
  const user = getUser();
  return user?.refresh;
}

function getLocalAccessToken() {
  const user = getUser();
  return user?.access;
}

function updateLocalAccessToken(token) {
  let user = getUser();
  user.access = token;
  localStorage.setItem("localUser", JSON.stringify(user));
}

function updateLocalUser(data) {
  let user = getUser();
  user.firstname = data.first_name;
  user.lastname = data.last_name;
  user.email = data.email;
  user.id = data.id;
  localStorage.setItem("localUser", JSON.stringify(user));
}

function getUser() {
  return JSON.parse(localStorage.getItem("localUser"));
}

function getAccess() {
  return JSON.parse(localStorage.getItem("roleAccess"));
}

function getUserId() {
  let user = localStorage.getItem("localUser")
    ? JSON.parse(localStorage.getItem("localUser"))
    : "";
  const decoded = user ? jwt_decode(user?.access) : 0;
  return decoded.user_id || null;
}

function setUser(user) {
  localStorage.setItem("localUser", JSON.stringify(user));

  switch (user?.kind) {
    case "Admin": {
      localStorage.setItem("roleAccess", JSON.stringify(ADMIN_ROLE_ACCESS));
      break;
    }
    case "Product": {
      localStorage.setItem("roleAccess", JSON.stringify(PRODUCT_ROLE_ACCESS));
      break;
    }
    default: {
      localStorage.removeItem("roleAccess");
      break;
    }
  }
}

function removeUser() {
  localStorage.removeItem("localUser");
  localStorage.removeItem("roleAccess");
}
