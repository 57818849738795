export const DATE_FORMAT = "MMM DD, YYYY HH:mm";

export const FETCH_LIMIT = 20;
export const PAGINATION_FETCH_LIMIT = 10;

export const EMAIL_PATTERN = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;

export const REQUIRED_ERROR = "Required";

export const PORTAL_KEY_TO_MAP = "PRODUCT-TABLEAU-HOME";

export const BUILD_ARGS = "BUILD_ARGUMENTS";
export const ZEPHY_ARGS = "ZEPHY_ARGS";
export const PARALLEL_ARGS = "PARALLEL_ARGUMENTS";

export const ERROR_MESSAGES = {
  noAccessMsg: "Failed to load detail! Please try again...",
  sessionExpired: "Session expired! Please login again",
  responce500:
    "An internal error was reported by the framework. You may retry and if the issue persists contact framework team.",
  serverUnreachable:
    "The framework didn’t respond. You may retry and if the issue persists contact framework team.",
  timeExceedError:
    "The framework took too long to respond. Please retry and if the issue persists contact framework team.",
  timeExceedErrorToCompaire: "timeout of 10000ms exceeded",
  refreshExpError: "You session has expired! Login again to continue.",
  tokenExpError: "",
  noRecordsFound: "No records to show",
  loadingRecords: "Fetching records…",
  failedLoadRecordsError:
    "There was an error in showing the records! You may retry…",
  oprationConformation:
    "This change cannot be undone. Would you like to proceed?",
  patternMatchErr: "Value doesn’t match expected format.",
  reConformMessage: "This change cannot be undone. Would you like to proceed?",
  reConformFormNotSaveMessage:
    "Changes made are not saved. Would you like to abandon these changes?",
  detailsFailsMessage:
    "There was an error in showing the detail! You may try again. If the issue persists contact framework team.",
  checkAllFieldsMessage:
    "Please correct the error indicated below respective fields",
  dashbordNoConfigureMessage: "A dashboard is not configured for this product",
  unableDeleteUser: "Unable to delete configuration",
  changePasswordErr: "Existing password entered is not correct",
};

export const SUCCESS_MESSAGES = {
  add: "Configuration added successfully",
  update: "Configuration updated successfully",
  delete: "Configuration deleted successfully",
};

export const TOOLTIP_MESSAGES = {
  operation: "No permission to perform this operation",
};

export const CONTENT_MESSAGES = {
  dashboardTitalDesc:
    "Select a product to view the dashboard.<br/>To setup the dashboard for your product contact the framework team.",
  productListTitalDesc:
    "List of products under test. Select a product to view/manage configuration.",
  productDetailPortalConfigDesc:
    "Save related portal URLs, like a dashboard URL",
  productDetailPrimaryOwnerDesc: "Primary owner of product (For contact only)",
  productDetailAppTableDesc:
    "Applications under the product, tests are run for.",
  appAuthkeyDesc:
    "Auth key to use when submitting tests for this app, using framework API.",
  appTestEnginesTableDesc:
    "Test engine configuration for the application with the Core Engine to use for each automation type.",
  testEngineFeaturesSecDesc:
    "Choose at least one Core Engine feature to create test builds for, when using this test engine.",
  productUsersTabTitalDesc:
    "Users with access to testing of this product. Owner role allows managing this user list.",
  buildListTitalDesc:
    "Select an application from a product and search for app test builds, or submit a new app test build.",
  buildExecutionEngineSecTitalDesc:
    "Select a execution engine, from configuration available for this application.",
  buildTestEngineSecTitalDesc:
    "Select a test engine, from configuration available for this application.",
  buildScriptDesc:
    "RobotFramework test script to run, from a Git-based repo with access information. The repo service must be whitelisted in framework.",
  buildScriptLocationDesc:
    "Relative path, file or directory, from the repo root, to the Robot Framework tests. Use Robot Framework arguments (e.g. scenario tags) below to filter scenarios.",
  buildArgumentsDesc:
    "Robot Framework command line arguments (e.g. Tag patterns) to use when running tests",
    executionEngineListingTitalDesc:
    "Execution engines available in framework. Select a Core engine to view/manage configuration.",
  coreEnginListingTitalDesc:
    "Core engines available in framework. Select a Core engine to view/manage configuration.",
  coreEnginProviderInfoDesc:
    "Information about the core engine, as name/value pairs. For information only.",
  coreEngineFeaturesTitalDesc:
    "Features available for end-users to use in the selected core engine. E.g. list of browsers supported.",
  fetureListTitalDesc:
    "Features are unique name/value pairs, describing a feature available for use in one or more core engines. e.g. A supported browser  to run tests with.",
  userListTitalDesc:
    "All users with access to the framework, including Administrators and Product users.",
  accessDeniedTital: "Access Restricted",
  accessDeniedDesc: "Current role does not permit this action.",
  notFoundPageTital: "Not found page",
  notFoundPageDesc:
    "The requested page is not found or access not allowed. Try using the menus to navigate back to your page.",
  coreEnginConfigDescription:
    "List of variables critical to execution of this Test engine. Configuration is typically not exposed to end users.",
  parallelRunArgsDesc:
    "Parallel run command line arguments (e.g. processes) to use when running tests",
  zephyrScaleCustomFields: "Zephyr scale custom fields",
};
